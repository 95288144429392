import styled from 'styled-components'

export default styled.div`
  .menu-navigation {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    background: #292929;
    padding: 14px 32px;
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: 0.2s;
    &.selected {
      background: #660c0d;
    }
    &:hover {
      background: #3d3d3d;
    }
    &::after {
      content: '';
      position: absolute;
      left: 32px; /*32px from padding*/
      bottom: 0px;
      width: calc(100% - 64px); /*64px from padding-left, padding-right*/
      height: 1px;
      background: #3d3d3d;
    }
    .menu-navigation-icon {
      width: 32px;
      height: 32px;
      margin-right: 8px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .icon-container {
    width: 32px;
    height: 32px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .menu-navigation-text {
    width: 100%;
    flex: 1;
  }
  .submenu-container {
    width: 100%;
    .submenu-wrapper {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 56px;
      background: #292929;
      padding: 14px 32px 14px 72px;
      cursor: pointer;
      &::after {
        content: '';
        position: absolute;
        left: 32px; /*32px from padding*/
        bottom: 0px;
        width: calc(100% - 64px); /*64px from padding-left, padding-right*/
        height: 1px;
        background: #3d3d3d;
      }
      &.selected {
        background: #4d090a;
      }
      &:hover {
        background: #3d3d3d;
      }
      .submenu-text {
        color: white;
        font-size: 18px;
      }
    }
  }
`
