import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import NavigationMenuStyled from './styled'

import UpIconSrc from '../../assets/images/leftbar-navigation/up-icon.svg'
import DownIconSrc from '../../assets/images/leftbar-navigation/down-icon.svg'

export class LeftBarNavigationMenu extends React.PureComponent {
  state = {
    openCollapse: this.props.selected,
  }

  toggleMenu() {
    this.setState({
      openCollapse: !this.state.openCollapse,
    })
  }

  onEachMenuClick = () => {
    if (this.props.menuData.subPath) {
      this.toggleMenu()
    } else {
      this.props.onClick(this.props.menuData.path)
    }
  }

  onEachSubMenuClick = path => {
    this.props.onClick(path)
  }

  renderSubPathMenu() {
    let output = null
    if (this.state.openCollapse && this.props.menuData.subPath) {
      const menus = this.props.menuData.subPath.map(subMenuData => {
        const preparePath = subMenuData.path.split('?')[0]
        const isActivePath = this.props.pathname === preparePath
        let className = 'submenu-wrapper'
        className += isActivePath ? ' selected' : ''
        return (
          <div
            id={`${this.props.id}-sub-menu-container`}
            data-test={`${this.props.id}-sub-menu-container`}
            key={subMenuData.text}
            className={className}
            onClick={() => this.onEachSubMenuClick(subMenuData.path)}
          >
            <div id={`${this.props.id}-sub-menu`} data-test={`${this.props.id}-sub-menu`} className="submenu-text">
              {subMenuData.text}
            </div>
          </div>
        )
      })
      output = (
        <div id={`${this.props.id}-sub-menu-container`} data-test={`${this.props.id}-sub-menu-container`} className="submenu-container">
          {menus}
        </div>
      )
    }
    return output
  }

  renderArrowCollapseMenu() {
    let output = null
    if (this.props.menuData.subPath) {
      output = (
        <div className="icon-container">
          <img src={this.state.openCollapse ? UpIconSrc : DownIconSrc} />
        </div>
      )
    }
    return output
  }

  render() {
    let menuClassName = 'menu-navigation'
    menuClassName += this.props.selected ? ' selected' : ''
    return (
      <NavigationMenuStyled id={this.props.id} data-test={this.props.id} className={this.props.className}>
        <div className={menuClassName} onClick={this.onEachMenuClick}>
          <div className="menu-navigation-icon">
            <img src={this.props.menuData.imgSrc} />
          </div>
          <div className="menu-navigation-text none-highlight">{this.props.menuData.text}</div>
          {this.renderArrowCollapseMenu()}
        </div>
        {this.renderSubPathMenu()}
      </NavigationMenuStyled>
    )
  }
}

LeftBarNavigationMenu.defaultProps = {
  id: 'left-bar-menu',
  className: '',
  selected: false,
}

LeftBarNavigationMenu.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  selected: PropTypes.bool,
  menuData: PropTypes.shape({
    text: PropTypes.string,
    imgSrc: PropTypes.string,
    path: PropTypes.string,
    subPath: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        path: PropTypes.string,
      }),
    ),
  }).isRequired,
  onClick: PropTypes.func,
  pathname: PropTypes.string,
  language: PropTypes.string,
}

export default withRouter(LeftBarNavigationMenu)
